import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Dashboard from "./dashboard";

import { getGeneral, getShopsReport, getTotalPricesUpdated, getTotalProductsCreated } from './dashboard-action'
import moment from 'moment-timezone';
import { hasPermission } from '../../utils/utils';

const DashboardContainer = () => {


    const dashboard = useSelector(state => state.dashboard);
    const current_user = useSelector(state => state.base.user);
    const selected_website = useSelector(state => state.base.selected_website)
    const dispatch = useDispatch();

    useEffect(() => {
        if (selected_website.id && dashboard.date_from && dashboard.date_to) {
            dispatch(getGeneral(selected_website.id, dashboard.date_from, dashboard.date_to));

            if (current_user && hasPermission(current_user, ["SUPER_ADMIN"]))
                dispatch(getShopsReport(selected_website.id, dashboard.date_from, dashboard.date_to));

            if (current_user && hasPermission(current_user, ["ROLE_PRICE_MODERATOR"]))
                dispatch(getTotalPricesUpdated(
                    selected_website.id,
                    dashboard.date_from,
                    dashboard.date_to
                ));

            if (current_user && hasPermission(current_user, ["SUPER_ADMIN"]))
                dispatch(getTotalProductsCreated(selected_website.id, dashboard.date_from, dashboard.date_to));
        }
    }, [dispatch, selected_website, current_user]);

    const handleDateChange = useCallback(
        (dates) => {

            dispatch(getGeneral(
                selected_website.id,
                moment(dates[0]).startOf("day").format('YYYY-MM-DD HH:mm:ss'),
                moment(dates[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss')
            ));


            if (current_user && hasPermission(current_user, ["SUPER_ADMIN"]))
                dispatch(getShopsReport(
                    selected_website.id,
                    moment(dates[0]).startOf("day").format('YYYY-MM-DD HH:mm:ss'),
                    moment(dates[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss')
                ));


            if (current_user && hasPermission(current_user, ["ROLE_PRICE_MODERATOR"]))
                dispatch(getTotalPricesUpdated(
                    selected_website.id,
                    moment(dates[0]).startOf("day").format('YYYY-MM-DD HH:mm:ss'),
                    moment(dates[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss')
                ));


            if (current_user && hasPermission(current_user, ["SUPER_ADMIN"]))
                dispatch(getTotalProductsCreated(
                    selected_website.id,
                    moment(dates[0]).startOf("day").format('YYYY-MM-DD HH:mm:ss'),
                    moment(dates[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss')
                ));

        }, [dispatch, selected_website.id, current_user]
    )


    return (
        <Dashboard
            dashboard={dashboard}
            handleDateChange={handleDateChange}
            current_user={current_user}
        />
    )
}

export default DashboardContainer;