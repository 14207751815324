import ptekaApi from '../services';

import { SET_NOTIFICATION } from "../_base/base-const";
import {
    LOADING_GENERAL_REPORT, GET_GENERAL_REPORT, GET_SHOPS_REPORT, LOADING_SHOPS_REPORT,
    GET_PRICES_UPDATED_REPORT, LOADING_PRICES_UPDATED_REPORT, GET_PRODUCTS_CREATED_REPORT, LOADING_PRODUCTS_CREATED_REPORT
} from "./dashboard-const";
import { formatError } from '../../utils/utils'


export const getGeneral = (website_id, date_from, date_to) => dispatch => {
    dispatch({ type: LOADING_GENERAL_REPORT, payload: true })
    ptekaApi.get('/reports/general', {
        params: {
            websites: [website_id],
            date_from: date_from,
            date_to: date_to
        }
    })
        .then(res => {
            dispatch({ type: LOADING_GENERAL_REPORT, payload: false })
            dispatch({
                type: GET_GENERAL_REPORT,
                payload: {
                    data: res.data.data,
                    date_from: date_from,
                    date_to: date_to
                }
            })
        })
        .catch(err => {
            dispatch({ type: LOADING_GENERAL_REPORT, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        });
}


export const getShopsReport = (website_id, date_from, date_to) => dispatch => {
    dispatch({ type: LOADING_SHOPS_REPORT, payload: true })
    ptekaApi.get('/reports/revenue-per-shop', {
        params: {
            websites: [website_id],
            date_from: date_from,
            date_to: date_to
        }
    })
        .then(res => {
            dispatch({ type: LOADING_SHOPS_REPORT, payload: false })
            dispatch({
                type: GET_SHOPS_REPORT,
                payload: {
                    data: res.data.data,
                    date_from: date_from,
                    date_to: date_to
                }
            })
        })
        .catch(err => {
            dispatch({ type: LOADING_SHOPS_REPORT, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        });
}


export const getTotalPricesUpdated = (website_id, date_from, date_to) => dispatch => {
    dispatch({ type: LOADING_PRICES_UPDATED_REPORT, payload: true })
    ptekaApi.get('/reports/total-prices-updated', {
        params: {
            website_id: website_id,
            date_from: date_from,
            date_to: date_to
        }
    })
        .then(res => {
            dispatch({ type: LOADING_PRICES_UPDATED_REPORT, payload: false })
            dispatch({
                type: GET_PRICES_UPDATED_REPORT,
                payload: {
                    data: res.data.data,
                    date_from: date_from,
                    date_to: date_to
                }
            })
        })
        .catch(err => {
            dispatch({ type: LOADING_PRICES_UPDATED_REPORT, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        });
}


export const getTotalProductsCreated = (website_id, date_from, date_to) => dispatch => {
    dispatch({ type: LOADING_PRODUCTS_CREATED_REPORT, payload: true })
    ptekaApi.get('/reports/total-products-created', {
        params: {
            website_id: website_id,
            date_from: date_from,
            date_to: date_to
        }
    })
        .then(res => {
            dispatch({ type: LOADING_PRODUCTS_CREATED_REPORT, payload: false })
            dispatch({
                type: GET_PRODUCTS_CREATED_REPORT,
                payload: {
                    data: res.data.data,
                    date_from: date_from,
                    date_to: date_to
                }
            })
        })
        .catch(err => {
            dispatch({ type: LOADING_PRODUCTS_CREATED_REPORT, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        });
}