
export const LOADING_GENERAL_REPORT = 'LOADING_GENERAL_REPORT'
export const GET_GENERAL_REPORT = 'GET_GENERAL_REPORT'


export const LOADING_SHOPS_REPORT = 'LOADING_SHOPS_REPORT'
export const GET_SHOPS_REPORT = 'GET_SHOPS_REPORT'

export const LOADING_PRICES_UPDATED_REPORT = 'LOADING_PRICES_UPDATED_REPORT'
export const GET_PRICES_UPDATED_REPORT = 'GET_PRICES_UPDATED_REPORT'

export const LOADING_PRODUCTS_CREATED_REPORT = 'LOADING_PRODUCTS_CREATED_REPORT'
export const GET_PRODUCTS_CREATED_REPORT = 'GET_PRODUCTS_CREATED_REPORT'


