

import {
    LOADING_GENERAL_REPORT,
    GET_GENERAL_REPORT,
    LOADING_SHOPS_REPORT,
    GET_SHOPS_REPORT,
    GET_PRICES_UPDATED_REPORT,
    LOADING_PRICES_UPDATED_REPORT,
    LOADING_PRODUCTS_CREATED_REPORT,
    GET_PRODUCTS_CREATED_REPORT
} from './dashboard-const';
import moment from 'moment-timezone';

const INITIAL_STATE = {
    date_from: moment().startOf("month").format('YYYY-MM-DD HH:mm:ss'),
    date_to: moment().format('YYYY-MM-DD HH:mm:ss'),
    general: {
        loading: false,
        data: []
    },
    shops: {
        loading: false,
        data: []
    },
    prices_updated: {
        loading: false,
        data: []
    },
    products_created: {
        loading: false,
        data: []
    }
};


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case GET_GENERAL_REPORT:
            return {
                ...state,
                date_from: action.payload.date_from,
                date_to: action.payload.date_to,
                general: {
                    ...state.general,
                    data: action.payload.data,
                }
            };
        case LOADING_GENERAL_REPORT:
            return {
                ...state,
                general: {
                    ...state.general,
                    loading: action.payload,
                }
            };

        case GET_SHOPS_REPORT:
            return {
                ...state,
                date_from: action.payload.date_from,
                date_to: action.payload.date_to,
                shops: {
                    ...state.shops,
                    data: action.payload.data,
                }
            };
        case LOADING_SHOPS_REPORT:
            return {
                ...state,
                shops: {
                    ...state.shops,
                    loading: action.payload,
                }
            };


        case GET_PRICES_UPDATED_REPORT:
            return {
                ...state,
                date_from: action.payload.date_from,
                date_to: action.payload.date_to,
                prices_updated: {
                    ...state.prices_updated,
                    data: action.payload.data,
                }
            };
        case LOADING_PRICES_UPDATED_REPORT:
            return {
                ...state,
                prices_updated: {
                    ...state.prices_updated,
                    loading: action.payload,
                }
            };

        case GET_PRODUCTS_CREATED_REPORT:
            return {
                ...state,
                date_from: action.payload.date_from,
                date_to: action.payload.date_to,
                products_created: {
                    ...state.products_created,
                    data: action.payload.data,
                }
            };
        case LOADING_PRODUCTS_CREATED_REPORT:
            return {
                ...state,
                products_created: {
                    ...state.products_created,
                    loading: action.payload,
                }
            };

        default:
            return state;
    }
}