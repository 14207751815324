import React, { useState, useEffect } from 'react';
import { Panel, Loader, FlexboxGrid, Table, IconButton, Icon } from 'rsuite';
import DatePicker from '../_common/date-picker'
import MetricChart from '../_common/charts/chart'
import BigCounter from '../_common/charts/big-counter'
import moment from 'moment-timezone';
import { orderBy } from 'lodash'
import { hasPermission } from '../../utils/utils';


const { Column, HeaderCell, Cell } = Table;
const rowKey = 'id';


const Dashboard = ({
    handleDateChange,
    dashboard,
    current_user
}) => {
    const [metricKey, setMetricKey] = useState("sessions");
    const [metricSuffix, setMetricSuffix] = useState("");
    const [numberType, setNumberType] = useState("integer");



    const [expandedRowKeys, setExpandedRowKeys] = useState([])

    const handleExpanded = (rowData, dataKey) => {

        let open = false;
        const nextExpandedRowKeys = [];

        expandedRowKeys.forEach(key => {
            if (key === rowData[rowKey]) {
                open = true;
            } else {
                nextExpandedRowKeys.push(key);
            }
        });

        if (!open) {
            nextExpandedRowKeys.push(rowData[rowKey]);
        }
        setExpandedRowKeys(nextExpandedRowKeys)
    }

    const [dataShops, setDataShops] = useState([]);
    const [summary, setSummary] = useState([]);


    const [sortingShops, setSortingShops] = useState({
        sort: "desc",
        sort_by: "total_revenue_clicks"
    })

    const [sortingSummaryCategories, setSortingSummaryCategories] = useState({
        sort: "desc",
        sort_by: "total_revenue_clicks"
    })

    useEffect(() => {
        let d = [];
        let s = 1;
        const tempSummary = {};

        for (const shop of dashboard.shops.data) {
            s++;
            const obj = {
                name: shop.name,
                data: [],
                id: s,
                total_cost: 0,
                total_cost_bidding: 0,
                total_clicks: 0,
                total_revenue_clicks: 0
            }
            let tclicks = 0;
            let tcost = 0;
            let tbcost = 0;
            let i = 1;

            if (shop.data)
                for (const click of shop.data) {
                    // Update shop-specific data
                    obj.data.push({
                        ...click,
                        clicks: click.clicks ? click.clicks : "-",
                        average: click.price ? (click.price / click.clicks) : "-",
                        id: i
                    })
                    tcost += click.price;
                    tbcost += click.bid_price;
                    tclicks += click.clicks;
                    i++;

                    // Update summary data
                    if (!tempSummary[click.name]) {
                        tempSummary[click.name] = { category: click.name, total_clicks: 0, total_cost: 0, total_cost_bidding: 0, total_revenue_clicks: 0 };
                    }
                    tempSummary[click.name].total_clicks += click.clicks;
                    tempSummary[click.name].total_cost += click.price;
                    tempSummary[click.name].total_cost_bidding += click.bid_price;
                    tempSummary[click.name].total_revenue_clicks += click.bid_price + click.price;
                }
            obj.total_cost = tcost;
            obj.total_clicks = tclicks;
            obj.total_cost_bidding = tbcost;
            obj.total_revenue_clicks = tcost + tbcost;
            d.push(obj);
        }

        let summaryArray = Object.values(tempSummary);

        if (sortingShops.sort && sortingShops.sort_by)
            d = orderBy(d, [sortingShops.sort_by], [sortingShops.sort])
        setDataShops(d);


        if (sortingSummaryCategories.sort && sortingSummaryCategories.sort_by)
            summaryArray = orderBy(summaryArray, [sortingSummaryCategories.sort_by], [sortingSummaryCategories.sort])
        setSummary(summaryArray);
    }, [dashboard.shops.data, sortingShops, sortingSummaryCategories]);


    const [totalUpdatedDates, setTotalUpdatedDates] = useState([]);
    const [totalUpdatedShops, setTotalUpdatedShops] = useState([]);

    useEffect(() => {


        function transformData(data) {
            const table = {};
            const dates = [...new Set(data.map(item => item.date))].sort();

            data.forEach(({ date, shop_name, updated_prices }) => {
                if (!table[shop_name]) {
                    table[shop_name] = {};
                }
                table[shop_name][date] = updated_prices;
            });

            const result = [];
            for (const shop in table) {
                const row = { shop_name: shop };
                dates.forEach(date => {
                    row[date] = table[shop][date] || 0;
                });
                result.push(row);
            }

            return { dates, result };
        }

        const { dates, result } = transformData(dashboard.prices_updated.data);

        setTotalUpdatedDates(dates)
        setTotalUpdatedShops(result)


    }, [dashboard.prices_updated.data]);



    const [totalUserCreatedProductsDates, setTotalUserCreatedProductsDates] = useState([]);
    const [totalUserCreatedProducts, setTotalUserCreatedProducts] = useState([]);

    useEffect(() => {


        function transformData(data) {
            const table = {};
            const dates = [...new Set(data.map(item => item.date))].sort();

            data.forEach(({ date, user_name, created_products }) => {
                if (!table[user_name]) {
                    table[user_name] = {};
                }
                table[user_name][date] = created_products;
            });

            const result = [];
            for (const user in table) {
                const row = { user_name: user };
                dates.forEach(date => {
                    row[date] = table[user][date] || 0;
                });
                result.push(row);
            }

            return { dates, result };
        }

        const { dates, result } = transformData(dashboard.products_created.data);


        setTotalUserCreatedProductsDates(dates)
        setTotalUserCreatedProducts(result)


    }, [dashboard.products_created.data]);




    return (
        <>
            <Panel header="Dashboard" shaded>
                <DatePicker
                    value={[
                        new Date(moment(dashboard.date_from, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")),
                        new Date(moment(dashboard.date_to, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"))
                    ]
                    }
                    onChange={(value) => {
                        handleDateChange(value)
                    }}
                />


                <FlexboxGrid className="py-2">
                    <FlexboxGrid.Item >
                        <BigCounter
                            metrics={dashboard.general.data}
                            metricKey={"sessions"}
                            header="Sessions"
                            loading={dashboard.general.loading}
                            selected={metricKey === "sessions"}
                            onClick={() => {
                                setMetricKey("sessions")
                                setMetricSuffix("")
                                setNumberType("integer")
                            }}
                            number={"integer"}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className='pl-1'>
                        <BigCounter
                            metrics={dashboard.general.data}
                            metricKey={"total_users"}
                            header="Users"
                            loading={dashboard.general.loading}
                            selected={metricKey === "total_users"}
                            onClick={() => {
                                setMetricKey("total_users")
                                setMetricSuffix("")
                                setNumberType("integer")
                            }}
                            number={"integer"}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-1">
                        <BigCounter
                            metrics={dashboard.general.data}
                            metricKey={"new_users"}
                            header="New Users"
                            loading={dashboard.general.loading}
                            selected={metricKey === "new_users"}
                            onClick={() => {
                                setMetricKey("new_users")
                                setMetricSuffix("")
                                setNumberType("integer")
                            }}
                            number={"integer"}
                        />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item className="pl-1">
                        <BigCounter
                            metrics={dashboard.general.data}
                            metricKey={"registered_users"}
                            header="Registered Users"
                            loading={dashboard.general.loading}
                            selected={metricKey === "registered_users"}
                            onClick={() => {
                                setMetricKey("registered_users")
                                setMetricSuffix("")
                                setNumberType("integer")
                            }}
                            number={"integer"}
                        />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item className="pl-1">
                        <BigCounter
                            metrics={dashboard.general.data}
                            metricKey={"newsletter_subscriber"}
                            header="Newsletter Subscribers"
                            loading={dashboard.general.loading}
                            selected={metricKey === "newsletter_subscriber"}
                            onClick={() => {
                                setMetricKey("newsletter_subscriber")
                                setMetricSuffix("")
                                setNumberType("integer")
                            }}
                            number={"integer"}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <FlexboxGrid className="pb-2">

                    <FlexboxGrid.Item>
                        <BigCounter
                            metrics={dashboard.general.data}
                            metricKey={"avg_engagement_time"}
                            header="Avg. Engagement Time"
                            loading={dashboard.general.loading}
                            selected={metricKey === "avg_engagement_time"}
                            onClick={() => {
                                setMetricKey("avg_engagement_time")
                                setMetricSuffix("s")
                                setNumberType("float")
                            }}
                            number={"float"}
                            suffix={"s"}
                            average={true}
                            minutes={true}
                        />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item className="pl-1">
                        <BigCounter
                            metrics={dashboard.general.data}
                            metricKey={"avg_session_duration"}
                            header="Avg. Session Duration"
                            loading={dashboard.general.loading}
                            selected={metricKey === "avg_session_duration"}
                            onClick={() => {
                                setMetricKey("avg_session_duration")
                                setMetricSuffix("s")
                                setNumberType("float")
                            }}
                            number={"float"}
                            suffix={"s"}
                            average={true}
                            minutes={true}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-1">
                        <BigCounter
                            metrics={dashboard.general.data}
                            metricKey={"page_views"}
                            header="Page Views"
                            loading={dashboard.general.loading}
                            selected={metricKey === "page_views"}
                            onClick={() => {
                                setMetricKey("page_views")
                                setMetricSuffix("")
                                setNumberType("integer")
                            }}
                            number={"integer"}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                {current_user && hasPermission(current_user, ["SUPER_ADMIN"]) ?
                    <FlexboxGrid>
                        <FlexboxGrid.Item>
                            <BigCounter
                                metrics={dashboard.general.data}
                                metricKey={"redirects"}
                                header="Clicks"
                                loading={dashboard.general.loading}
                                selected={metricKey === "redirects"}
                                onClick={() => {
                                    setMetricKey("redirects")
                                    setMetricSuffix("")
                                    setNumberType("integer")
                                }}
                                number={"integer"}
                            />
                        </FlexboxGrid.Item>


                        <FlexboxGrid.Item className="pl-1">
                            <BigCounter
                                metrics={dashboard.general.data}
                                metricKey={"revenue"}
                                header="Revenue Clicks"
                                loading={dashboard.general.loading}
                                selected={metricKey === "revenue"}
                                onClick={() => {
                                    setMetricKey("revenue")
                                    setMetricSuffix("€")
                                    setNumberType("float")
                                }}
                                number={"float"}
                                suffix={"€"}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item className="pl-1">
                            <BigCounter
                                metrics={dashboard.general.data}
                                metricKey={"revenue_bidding_clicks"}
                                header="Revenue Bidding Clicks"
                                loading={dashboard.general.loading}
                                selected={metricKey === "revenue_bidding_clicks"}
                                onClick={() => {
                                    setMetricKey("revenue_bidding_clicks")
                                    setMetricSuffix("€")
                                    setNumberType("float")
                                }}
                                number={"float"}
                                suffix={"€"}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item className="pl-1">
                            <BigCounter
                                metrics={dashboard.general.data}
                                metricKey={"revenue_cpm"}
                                header="Revenue Bidding CPM"
                                loading={dashboard.general.loading}
                                selected={metricKey === "revenue_cpm"}
                                onClick={() => {
                                    setMetricKey("revenue_cpm")
                                    setMetricSuffix("€")
                                    setNumberType("float")
                                }}
                                number={"float"}
                                suffix={"€"}
                            />
                        </FlexboxGrid.Item>


                        <FlexboxGrid.Item className="pl-1">
                            <BigCounter
                                metrics={dashboard.general.data}
                                metricKey={"total_revenue"}
                                header="TOTAL Revenue"
                                loading={dashboard.general.loading}
                                selected={metricKey === "total_revenue"}
                                onClick={() => {
                                    setMetricKey("total_revenue")
                                    setMetricSuffix("€")
                                    setNumberType("float")
                                }}
                                number={"float"}
                                suffix={"€"}
                            />
                        </FlexboxGrid.Item>


                        <FlexboxGrid.Item className={"pt-3"} style={{ width: "100%", height: 300 }}>
                            {!dashboard.general.loading ?
                                <MetricChart
                                    metrics={dashboard.general.data}
                                    metricKey={metricKey}
                                    start={moment(dashboard.date_from, "YYYY-MM-DD HH:mm:ss")}
                                    end={moment(dashboard.date_to, "YYYY-MM-DD HH:mm:ss")}
                                    suffix={metricSuffix}
                                    number={numberType}
                                />
                                : <Loader />}

                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    : ""}

                {current_user && hasPermission(current_user, ["SUPER_ADMIN"]) ?
                    <>
                        <h6 className='pt-4 pb-2'>{"Revenue by shop"}</h6>

                        <Table
                            affixHeader={60}
                            loading={dashboard.shops.loading}
                            //height={750}
                            width={800}
                            wordWrap
                            autoHeight
                            data={dataShops}
                            rowKey={rowKey}
                            expandedRowKeys={expandedRowKeys}
                            renderRowExpanded={ExpandedRow}
                            //rowHeight={80}
                            rowExpandedHeight={500}
                            sortColumn={sortingShops.sort_by}
                            sortType={sortingShops.sort}
                            onSortColumn={(sort_by, sort) => { setSortingShops({ sort_by: sort_by, sort: sort }) }}
                        >
                            <Column width={70} align="center">
                                <HeaderCell>#</HeaderCell>
                                <ExpandCell
                                    dataKey="id"
                                    expandedRowKeys={expandedRowKeys}
                                    onChange={handleExpanded}
                                />
                            </Column>

                            <Column width={200} sortable>
                                <HeaderCell>Name</HeaderCell>
                                <Cell dataKey="name" />
                            </Column>
                            <Column width={120} sortable>
                                <HeaderCell>Clicks</HeaderCell>
                                <Cell dataKey="total_clicks" />
                            </Column>
                            <Column width={120} sortable>
                                <HeaderCell>Clicks Costs</HeaderCell>
                                <RevenueCell dataKey="total_cost" />
                            </Column>
                            <Column width={120} sortable>
                                <HeaderCell>Bid Costs</HeaderCell>
                                <RevenueBiddingCell dataKey="total_cost_bidding" />
                            </Column>
                            <Column width={120} sortable>
                                <HeaderCell>Revenue</HeaderCell>
                                <RevenueTotalCell dataKey="total_revenue_clicks" />
                            </Column>
                        </Table>
                        <h6 className='pt-4 pb-2'>{"Revenue by category"}</h6>
                        <Table
                            affixHeader={60}
                            loading={dashboard.shops.loading}
                            //height={750}
                            width={800}
                            wordWrap
                            autoHeight
                            data={summary}
                            rowKey={rowKey}
                            sortColumn={sortingSummaryCategories.sort_by}
                            sortType={sortingSummaryCategories.sort}
                            onSortColumn={(sort_by, sort) => { setSortingSummaryCategories({ sort_by: sort_by, sort: sort }) }}
                        >
                            <Column width={250} sortable>
                                <HeaderCell>Name</HeaderCell>
                                <Cell dataKey="category" />
                            </Column>
                            <Column width={120} sortable>
                                <HeaderCell>Clicks</HeaderCell>
                                <Cell dataKey="total_clicks" />
                            </Column>
                            <Column width={120} sortable>
                                <HeaderCell>Clicks Cost</HeaderCell>
                                <RevenueCell dataKey="total_cost" />
                            </Column>
                            <Column width={120} sortable>
                                <HeaderCell>Bid Cost</HeaderCell>
                                <RevenueBiddingCell dataKey="total_cost_bidding" />
                            </Column>
                            <Column width={120} sortable>
                                <HeaderCell>Revenue</HeaderCell>
                                <RevenueTotalCell dataKey="total_revenue_clicks" />
                            </Column>


                        </Table>
                    </>
                    : ""}


                {current_user && hasPermission(current_user, ["ROLE_PRICE_MODERATOR"]) ?
                    <>
                        <h6 className='pt-4 pb-2'>{"Prices Updated"}</h6>
                        <Table
                            affixHeader={60}
                            loading={dashboard.prices_updated.loading}
                            height={500}
                            width={800}
                            wordWrap
                            //autoHeight
                            data={totalUpdatedShops}
                        >

                            <Column width={150} fixed={"left"}>
                                <HeaderCell>Shop</HeaderCell>
                                <Cell dataKey="shop_name" />
                            </Column>

                            {totalUpdatedDates.length > 0 ? totalUpdatedDates.map((date, index) => (
                                <Column width={60} key={"k"+index}>
                                    <HeaderCell align={"center"}>{date.split("-")[2] + "." + date.split("-")[1]}</HeaderCell>
                                    <Cell dataKey={date} align={"center"} />
                                </Column>
                            )) : ""}
                        </Table>
                    </>
                    : ""}


                {current_user && hasPermission(current_user, ["SUPER_ADMIN"]) ?
                    <>
                        <h6 className='pt-4 pb-2'>{"Products Created"}</h6>
                        <Table
                            affixHeader={60}
                            loading={dashboard.products_created.loading}
                            //height={750}
                            width={800}
                            height={500}
                            wordWrap
                            //autoHeight
                            data={totalUserCreatedProducts}
                        >

                            <Column width={150} fixed={"left"}>
                                <HeaderCell>User</HeaderCell>
                                <Cell dataKey="user_name" />
                            </Column>

                            {totalUserCreatedProductsDates.length > 0 ? totalUserCreatedProductsDates.map((date, index) => (
                                <Column width={60} key={"d"+index}>
                                    <HeaderCell align={"center"}>{date.split("-")[2] + "." + date.split("-")[1]}</HeaderCell>
                                    <Cell dataKey={date} align={"center"} />
                                </Column>
                            )) : ""}
                        </Table>
                    </>
                    : ""}
                <div style={{ paddingBottom: 300 }} />
            </Panel>
        </>
    );
}

const ExpandedRow = (rowData) => {





    return (
        rowData && rowData.data ?
            <Table
                autoHeight
                data={rowData.data}
            //loading={clicks.page.loading}
            >
                <Column width={40}>
                    <HeaderCell></HeaderCell>
                    <Cell dataKey="id" />
                </Column>
                <Column width={250}>
                    <HeaderCell>Category</HeaderCell>
                    <Cell dataKey="name" />
                </Column>
                <Column width={120} align={"center"}>
                    <HeaderCell>Clicks</HeaderCell>
                    <Cell dataKey="clicks" />
                </Column>
                <Column width={120} align={"center"}>
                    <HeaderCell>Average PPC</HeaderCell>
                    <PriceCell dataKey="average" />
                </Column>
                <Column width={120} align={"center"}>
                    <HeaderCell>Clicks</HeaderCell>
                    <TotalCell dataKey="price" />
                </Column>
                <Column width={120} align={"center"}>
                    <HeaderCell>Bid</HeaderCell>
                    <TotalBidCell dataKey="bid_price" />
                </Column>
                {/* <Column width={120} sortable>
                    <HeaderCell>Bid Cost</HeaderCell>
                    <RevenueBiddingCell dataKey="total_cost_bidding" />
                </Column> */}
            </Table>
            : <></>
    )
}

const numberFormat = (num, decimal = 0) => {
    if (!num) return 0
    return (
        num
            .toFixed(decimal) // always two decimal digits
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    ) // use . as a separator
}

const RevenueCell = ({ rowData, dataKey, ...props }) => {

    return (
        <Cell {...props}>

            {rowData.average != "-" ? <>
                {numberFormat(rowData.total_cost, 2)}<span className='currency'>{" €"}</span>
            </> : ""}


        </Cell >
    );
}

const RevenueBiddingCell = ({ rowData, dataKey, ...props }) => {

    return (
        <Cell {...props}>

            {rowData.average != "-" ? <>
                {numberFormat(rowData.total_cost_bidding, 2)}<span className='currency'>{" €"}</span>
            </> : ""}


        </Cell >
    );
}

const RevenueTotalCell = ({ rowData, dataKey, ...props }) => {

    return (
        <Cell {...props}>

            {rowData.average != "-" ? <>
                {numberFormat(rowData.total_revenue_clicks, 2)}<span className='currency'>{" €"}</span>
            </> : ""}


        </Cell >
    );
}

const PriceCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Cell {...props}>

            {rowData.average != "-" ? <>
                {numberFormat(rowData.average, 2)}<span className='currency'>{" €"}</span>
            </> : ""}


        </Cell >
    );
}

const TotalCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Cell {...props}>
            {rowData.price > 0 ? <>{numberFormat(rowData.price, 2)}<span className='currency'>{" €"}</span> </> : "-"}
        </Cell>
    );
}
const TotalBidCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Cell {...props}>
            {rowData.bid_price > 0 ? <>{numberFormat(rowData.bid_price, 2)}<span className='currency'>{" €"}</span> </> : "-"}
        </Cell>
    );
}




const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }) => (
    <Cell {...props}>
        <IconButton
            size="xs"
            appearance="subtle"
            onClick={() => {
                onChange(rowData);
            }}
            icon={
                <Icon
                    icon={
                        expandedRowKeys.some(key => key === rowData[rowKey])
                            ? 'minus-square-o'
                            : 'plus-square-o'
                    }
                />
            }
        />
    </Cell>
);






export default Dashboard;